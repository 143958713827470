<template>
  <v-row>
    <v-col
      cols="12"
      lg="7"
      xl="6"
      class="info d-none d-md-flex align-center justify-center"
    >
      <SupportArht />
    </v-col>
    <v-col cols="12" lg="5" xl="6" class="d-flex align-center">
      <v-container>
        <div class="pa-7 pa-sm-12">
          <v-row>
            <v-col cols="12" lg="9" xl="6">
              <img src="@/assets/img/arhtLogo.png" />
              <h6 class="subtitle-1">ARHT Engine customer portal</h6>
              <h2 class="font-weight-bold mt-4 white--text">Sign in</h2>

              <v-form ref="form" data-vv-scope="form-login">
                <ul v-if="errors_api" class="error-messages">
                  <li v-for="(v, k) in errors_api" :key="k">{{ v }}</li>
                </ul>
                <v-alert
                  :value="true"
                  type="error"
                  v-if="errors.has('form-login.general_error_field')"
                >
                  {{ errors.first("form-login.general_error_field") }}
                </v-alert>

                <v-text-field
                  v-model.trim="email"
                  data-vv-name="email"
                  v-validate="'required|emailWithSpaces'"
                  label="E-mail"
                  class="mt-4"
                  required
                  outlined
                  data-testid="email"
                  :error-messages="errors.collect('form-login.email')"
                ></v-text-field>

                <v-text-field
                  v-model="password"
                  data-vv-name="password"
                  v-validate="'required'"
                  label="Password"
                  required
                  outlined
                  :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="showPass ? 'text' : 'password'"
                  @click:append="showPass = !showPass"
                  :error-messages="errors.collect('form-login.password')"
                  v-on:keyup.enter="submit"
                  data-testid="password"
                ></v-text-field>

                <div class="d-block d-sm-flex align-center mb-4 mb-sm-0">
                  <div class="ml-auto">
                    <a
                      href="/auth/password/reset"
                      class="link"
                      data-testid="link-forgot-password"
                      >Forgot your password?</a
                    >
                  </div>
                </div>
                <v-btn
                  :disabled="
                    !(
                      errors.items.length === 0 &&
                      password.length > 0 &&
                      email.length > 0
                    )
                  "
                  color="info"
                  block
                  class="mr-4 mt-4"
                  submit
                  @click="submit"
                  id="btn-login"
                  data-testid="btn-login"
                >
                  Login
                </v-btn>
<!--                <div>-->
<!--                  <v-btn-->
<!--                    :disabled="!microsoftLink"-->
<!--                    color="deep-orange darken-1"-->
<!--                    block-->
<!--                    class="mr-4 mt-6"-->
<!--                    @click="loginMicrosoft()"-->
<!--                  >-->
<!--                    <v-icon left dark> mdi-microsoft-windows </v-icon>-->
<!--                    Login with microsoft-->
<!--                  </v-btn>-->
<!--                </div>-->
              </v-form>
            </v-col>
          </v-row>
        </div>
      </v-container>
    </v-col>
  </v-row>
</template>

<script>
import "@/validators/login/login-validator"
import { mapState } from "vuex"
import GeneralMixin from "@/mixins/general"
import GraylogService from "@/common/graylog.service"
import SupportArht from "./SupportArht"

export default {
  components: {
    SupportArht,
  },

  mixins: [GeneralMixin],

  data() {
    return {
      showPass: false,
      email: "",
      password: "",
      dictionary: {
        attributes: {
          email: "E-mail Address",
          password: "Password",
        },
        custom: {},
      },
      microsoftLink: null,
    }
  },

  mounted() {
    let self = this
    this.$validator.localize("en", this.dictionary)
    let redirect_url = `${window.location.origin}/login/microsoft/`
    let payload = {
      params: {
        redirect_uri: redirect_url,
      },
    }
    self.$store.dispatch("getLoginMicrosoftLink", payload).then((response) => {
      self.microsoftLink = response?.data?.data?.redirect_url
    })
  },

  methods: {
    submit() {
      let self = this
      let email = self.email.toString().trim()
      let password = self.password
      let credentials = {
        email: email,
        password: password,
      }
      self.$validator.validateAll("form-login").then((result) => {
        if (result) {
          self.$store
            .dispatch("login", credentials)
            .then(() => self.$router.push({ name: "home" }).catch((err) => {}))
            .catch((error) => {
              self.handleValidationErrors(error.response, "form-login")
              let msg = {
                short_message: "Login website error",
                full_message: JSON.stringify(error?.response?.data?.error),
                level: GraylogService.ERROR_LEVEL,
                source: "front.end",
              }
              GraylogService.dump(["prod"], msg)
            })
        }
      })
    },
    loginMicrosoft() {
      window.location.href = this.microsoftLink
    },
  },

  computed: {
    ...mapState({
      errors_api: (state) => state.auth.errors,
    }),
  },
}
</script>
