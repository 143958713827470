import { graylogProdApiInstance, graylogDevApiInstance } from "./config"

class GraylogService {
  static DEBUG_LEVEL = 7
  static INFO_LEVEL = 6
  static WARNING_LEVEL = 4
  static ERROR_LEVEL = 3
  static CRITICAL_LEVEL = 2

  constructor() {
    this.instance = this.getApiInstance()
  }

  dump(sendTo, ...data) {
    let self = this
    if (sendTo.length > 0) {
      sendTo.forEach((dest) => {
        if (self.sendLog(dest)) {
          self.post(...data)
        }
      })
    }
  }

  post(data) {
    let config = {
      method: "post",
      data: data,
    }
    this.instance.request(config)
  }

  getApiInstance() {
    return this.isProdSite() ? graylogProdApiInstance : graylogDevApiInstance
  }

  isDevSite() {
    return window.location.hostname === "dev.arht.redox-dev.com"
  }

  isStageSite() {
    return window.location.hostname === "stage-arht.redox-dev.com"
  }

  isProdSite() {
    return window.location.hostname === "app.arhtengine.com"
  }

  sendLog(dest) {
    if (dest === "dev" && this.isDevSite()) return true
    if (dest === "stage" && this.isStageSite()) return true
    if (dest === "prod" && this.isProdSite()) return true
    return false
  }

  // change graylog instance
  setGraylogInstance(type = "dev") {
    if (type === "dev") {
      this.instance = graylogDevApiInstance
    }
    if (type === "prod") {
      this.instance = graylogProdApiInstance
    }
  }
}

export default new GraylogService()
